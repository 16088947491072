<template>
  <select @change="onUpdate($event.target.value)" :class="className">
    <option v-for="(item) in items" v-bind:key="item.value"
            :value="item.value" :selected="value === item.value">
      {{ $t(item.name) }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'UiSelect',
  props: [
    'items',
    'value',
    'type',
    'className'
  ],
  emits: [
    'input'
  ],
  data () {
    return {
      itemList: []
    }
  },
  methods: {
    onUpdate (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
